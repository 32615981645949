import {
  FontIcon,
  FontSizes,
  FontWeights,
  ITheme,
  mergeStyleSets,
  PersonaCoin,
  PersonaSize,
  Stack,
  useTheme,
} from '@fluentui/react'
import { Channel } from '@notidar/api'
import { Link } from 'react-router-dom'
import { headerHeight } from '../View'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    container: {
      backgroundColor: theme.palette.neutralQuaternary,
      height: headerHeight,
    },
    left: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      width: 60,
    },
    center: {
      marginLeft: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: FontSizes.large,
      fontWeight: FontWeights.semibold,
    },
    centerBottom: {
      alignItems: 'start',
      display: 'flex',
    },
    centerTop: {
      alignItems: 'end',
      display: 'flex',
      fontSize: FontSizes.large,
      fontWeight: FontWeights.semibold,
    },
    right: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      width: 60,
    },
    logo: {
      verticalAlign: 'bottom',
      height: 48,
      width: 48,
      cursor: 'pointer',
    },
    link: {
      color: 'unset',
      textDecoration: 'none',
      ':hover, :active, :active:hover': {
        textDecoration: 'underline',
      },
    },
    solidLink: {
      color: 'unset',
      textDecoration: 'none',
    },
  })
}

export interface WidgetChannelHeaderProps {
  channel: Channel
  notidarLink: string
  channelLink: string
  state: 'succeeded'
}

export interface WidgetHeaderProps {
  notidarLink: string
  state: 'loading' | 'error' | 'empty'
}

export const WidgetHeader = (props: WidgetHeaderProps | WidgetChannelHeaderProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme)

  return (
    <Stack className={classNames.container} horizontal horizontalAlign='space-between'>
      {props.state === 'succeeded' && (
        <>
          <Stack.Item disableShrink className={classNames.left}>
            <Link className={classNames.solidLink} target='_blank' to={props.channelLink}>
              <PersonaCoin
                {...{
                  size: PersonaSize.size48,
                }}
                hidePersonaDetails
                text={props.channel.title}
              />
            </Link>
          </Stack.Item>
          <Stack grow>
            <Stack.Item grow className={classNames.centerTop}>
              <Link className={classNames.link} target='_blank' to={props.channelLink}>
                {props.channel.title}
              </Link>
            </Stack.Item>
            <Stack.Item grow className={classNames.centerBottom}>
              <Link className={classNames.solidLink} target='_blank' to={props.channelLink}>
                @{props.channel.name}
              </Link>
            </Stack.Item>
          </Stack>
        </>
      )}
      {props.state === 'loading' && (
        <Stack.Item disableShrink className={classNames.center}>
          Loading...
        </Stack.Item>
      )}
      {(props.state === 'error') && (
        <Stack.Item disableShrink className={classNames.center}>
          Something went wrong...
        </Stack.Item>
      )}
      {props.state === 'empty' && (
        <Stack.Item disableShrink className={classNames.center}>
          <Link className={classNames.link} target='_blank' to={props.notidarLink}>
            Nothing here, see more on notidar.com
          </Link>
        </Stack.Item>
      )}
      <Stack.Item disableShrink className={classNames.right}>
        <Link className={classNames.solidLink} target='_blank' to={props.notidarLink}>
          <FontIcon aria-label='Notidar' iconName='custom_notidar' className={classNames.logo} />
        </Link>
      </Stack.Item>
    </Stack>
  )
}
