import { useParams, useSearchParams } from 'react-router-dom'
import { ChannelIdentifier, PostId } from '@notidar/content'
import { Widget, WidgetLang, WidgetTheme } from './widgets/Widget'
import { ThemeProvider, createTheme } from '@fluentui/react'

const lightTheme = createTheme({
  defaultFontStyle: {
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI Regular Cyrillic", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  },
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
})

const darkTheme = createTheme({
  defaultFontStyle: {
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI Regular Cyrillic", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  },
  palette: {
    themePrimary: '#2f81f7',
    themeLighterAlt: '#02050a',
    themeLighter: '#081528',
    themeLight: '#0e274a',
    themeTertiary: '#1c4e94',
    themeSecondary: '#2973da',
    themeDarkAlt: '#438ff8',
    themeDark: '#60a0f9',
    themeDarker: '#89b8fb',
    neutralLighterAlt: '#262626',
    neutralLighter: '#2f2f2f',
    neutralLight: '#3d3d3d',
    neutralQuaternaryAlt: '#464646',
    neutralQuaternary: '#4d4d4d',
    neutralTertiaryAlt: '#6b6b6b',
    neutralTertiary: '#f8f8f8',
    neutralSecondary: '#f9f9f9',
    neutralSecondaryAlt: '#f9f9f9',
    neutralPrimaryAlt: '#fafafa',
    neutralPrimary: '#f6f6f6',
    neutralDark: '#fdfdfd',
    black: '#fefefe',
    white: '#1c1c1c',
  },
})

export type WidgetType = 'feed' | 'post' | 'channel'
export const typeQuery = 'type'
export const widthQuery = 'width'
export const heightQuery = 'height'
export const themeQuery = 'theme'
export const langQuery = 'lang'

export const headerHeight = 60
export const footerHeight = 42
export const defaultWidth = 512
export const maxWidth = 1024
export const minWidth = 280
export const defaultHeight = 1024
export const maxHeight = 1024
export const minHeight = 280

export const View = () => {
  const { channelIdentifier, postId } = useParams<{ channelIdentifier: ChannelIdentifier; postId: PostId }>()
  let [searchParams] = useSearchParams()

  const type: WidgetType = (searchParams.get(typeQuery) as WidgetType) ?? undefined
  let width: number = parseInt(searchParams.get(widthQuery) ?? '') || defaultWidth
  let height: number | undefined = parseInt(searchParams.get(heightQuery) ?? '') || defaultHeight
  const theme: WidgetTheme = (searchParams.get(themeQuery) as WidgetTheme) ?? 'light'
  const lang: WidgetLang = (searchParams.get(langQuery) as WidgetLang) ?? 'en'

  width = width > maxWidth ? maxWidth : width < minWidth ? minWidth : width
  height = height > maxHeight ? maxHeight : height < minHeight ? minHeight : height

  return (
    <ThemeProvider style={{ height: '100%', backgroundColor: 'transparent' }} theme={theme === 'dark' ? darkTheme : lightTheme}>
      <Widget
        channelIdentifier={channelIdentifier}
        type={type}
        postId={postId}
        height={height}
        width={width}
        lang={lang}
        theme={theme}
      />
    </ThemeProvider>
  )
}
