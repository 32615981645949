import { WidgetContainer } from '../components/Container'
import { WidgetHeader } from '../components/Header'

export interface EmptyWidgetProps {
  width: number
  notidarLink: string
}

export const EmptyWidget = ({ notidarLink, width }: EmptyWidgetProps) => {
  return (
    <WidgetContainer width={width}>
      <WidgetHeader notidarLink={notidarLink} state={'empty'}></WidgetHeader>
    </WidgetContainer>
  )
}
