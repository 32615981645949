import { Channel, Post } from '@notidar/api'
import { WidgetBody } from '../components/Body'
import { WidgetFooter } from '../components/Footer'
import { WidgetHeader } from '../components/Header'
import { footerHeight, headerHeight } from '../View'
import { WidgetContainer } from '../components/Container'
import { ChannelPosts } from '../components/content/ChannelPosts'

export interface ChannelFeedWidgetProps {
  channel: Channel
  posts: Post[]
  width: number
  height: number
  notidarLink: string
  channelLink: string
}

export const ChannelFeedWidget = ({
  channel,
  posts,
  channelLink,
  notidarLink,
  height,
  width,
}: ChannelFeedWidgetProps) => {
  return (
    <WidgetContainer width={width}>
      <WidgetHeader notidarLink={notidarLink} channelLink={channelLink} channel={channel} state='succeeded' />
      <WidgetBody maxHeight={height - headerHeight - footerHeight}>
        <ChannelPosts channel={channel} posts={posts} />
      </WidgetBody>
      <WidgetFooter state='seeMore' redirectLink={channelLink} />
    </WidgetContainer>
  )
}
