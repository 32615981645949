import { ITheme, mergeStyleSets, PrimaryButton, Stack, useTheme } from '@fluentui/react'
import { footerHeight } from '../View'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    container: {
      backgroundColor: theme.palette.neutralQuaternary,
      padding: '5px 10px',
      height: footerHeight
    },
  })
}

export interface WidgetSeeMoreFooterProps {
  state: 'seeMore' | 'see'
  redirectLink: string
}

export const WidgetFooter = ({ redirectLink, state }: WidgetSeeMoreFooterProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme)

  return (
    <Stack horizontalAlign='center' className={classNames.container}>
      {state === 'seeMore' && <PrimaryButton href={redirectLink} target='_blank' text={'See more on Notidar'} />}
      {state === 'see' && <PrimaryButton href={redirectLink} target='_blank' text={'See on Notidar'} />}
    </Stack>
  )
}
