import { WidgetContainer } from '../components/Container'
import { WidgetHeader } from '../components/Header'

export interface LoadingWidgetProps {
  width: number
  notidarLink: string
}

export const LoadingWidget = ({ notidarLink, width }: LoadingWidgetProps) => {
  return (
    <WidgetContainer width={width}>
      <WidgetHeader notidarLink={notidarLink} state={'loading'} />
    </WidgetContainer>
  )
}
