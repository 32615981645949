import { WidgetContainer } from '../components/Container'
import { WidgetHeader } from '../components/Header'

export interface ErrorWidgetProps {
  width: number
  notidarLink: string
}

export const ErrorWidget = ({ notidarLink, width }: ErrorWidgetProps) => {
  return (
    <WidgetContainer width={width}>
      <WidgetHeader notidarLink={notidarLink} state='error' />
    </WidgetContainer>
  )
}
