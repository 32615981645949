import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { initializeIcons, mergeStyles, registerIcons } from '@fluentui/react'
import { View } from './View'

console.debug(process.env)

registerIcons({
  icons: {
    custom_notidar: (
      <svg viewBox='10 10 100 100' xmlns='http://www.w3.org/2000/svg'>
        <title>Notidar</title>
        <ellipse stroke='#0078d4' strokeWidth='10' fill='none' cx='60' cy='60' id='svg_1' rx='45' ry='45' />
        <ellipse stroke='#0078d4' fill='none' strokeWidth='10' cx='60' cy='60' id='svg_3' rx='25' ry='25' />
        <ellipse fill='#0078d4' strokeWidth='0' cx='60' cy='60' id='svg_6' rx='10' ry='10' stroke='#000' />
      </svg>
    ),
    custom_rss: (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2 4.5C2 3.119 3.119 2 4.5 2H11.5C12.881 2 14 3.119 14 4.5V11.5C14 12.881 12.881 14 11.5 14H4.5C3.119 14 2 12.881 2 11.5V4.5ZM4.5 3C3.672 3 3 3.672 3 4.5V11.5C3 12.328 3.672 13 4.5 13H11.5C12.328 13 13 12.328 13 11.5V4.5C13 3.672 12.328 3 11.5 3H4.5ZM5.5 11.25C5.914 11.25 6.25 10.914 6.25 10.5C6.25 10.086 5.914 9.75 5.5 9.75C5.086 9.75 4.75 10.086 4.75 10.5C4.75 10.914 5.086 11.25 5.5 11.25ZM9 10.5C9 8.57 7.43 7 5.5 7C5.224 7 5 7.224 5 7.5C5 7.776 5.224 8 5.5 8C6.879 8 8 9.121 8 10.5C8 10.776 8.224 11 8.5 11C8.776 11 9 10.776 9 10.5ZM12 10.5C12 6.916 9.084 4 5.5 4C5.224 4 5 4.224 5 4.5C5 4.776 5.224 5 5.5 5C8.532 5 11 7.468 11 10.5C11 10.776 11.224 11 11.5 11C11.776 11 12 10.776 12 10.5Z'
          fill='#212121'
        />
      </svg>
    ),
  },
})

// init fluent icons
initializeIcons()

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
  },
})

const router = createBrowserRouter([
  {
    path: 'channel/:channelIdentifier',
    element: <View></View>,
  },
  {
    path: 'channel/:channelIdentifier/post/:postId',
    element: <View></View>,
  },
  {
    path: '*',
    element: <View></View>,
  },
])

const rootElement = document.getElementById('root')!
const resizeObserver = new ResizeObserver(entries => {
  if (entries.length === 1) {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('notidar-widget-id')
    window.parent.postMessage(
      {
        type: 'notidar-widget-resize',
        id,
        height: entries[0].contentRect.height,
      },
      '*',
    )
  }
})
resizeObserver.observe(rootElement)

const root = ReactDOM.createRoot(rootElement as HTMLElement)
root.render(
  //<React.StrictMode>
  <RouterProvider router={router} />,
  //</React.StrictMode>,
)
