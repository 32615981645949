import { Channel } from '@notidar/api'
import { WidgetBody } from '../components/Body'
import { WidgetFooter } from '../components/Footer'
import { WidgetHeader } from '../components/Header'
import { ChannelDescription } from '../components/content/ChannelDescription'
import { footerHeight, headerHeight } from '../View'
import { WidgetContainer } from '../components/Container'

export interface ChannelWidgetProps {
  channel: Channel
  width: number
  height: number
  notidarLink: string
  channelLink: string
}

export const ChannelWidget = ({ channel, channelLink, notidarLink, width, height }: ChannelWidgetProps) => {
  return (
    <WidgetContainer width={width}>
      <WidgetHeader channel={channel} channelLink={channelLink} notidarLink={notidarLink} state='succeeded' />
      {channel.description && (
        <>
          <WidgetBody maxHeight={height - headerHeight - footerHeight}>
            <ChannelDescription description={channel.description}></ChannelDescription>
          </WidgetBody>
          <WidgetFooter state='see' redirectLink={channelLink} />
        </>
      )}
    </WidgetContainer>
  )
}
