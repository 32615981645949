import { mergeStyleSets } from '@fluentui/react'
import { Channel, Post } from '@notidar/api'
import { PostCard } from '@notidar/content/src/cards/PostCard'

const getClassNames = () => {
  return mergeStyleSets({
    card: {
      paddingBottom: 10,
    },
  })
}

export interface ChannelPostsProps {
  channel: Channel
  posts: Post[]
}

export const ChannelPosts = ({ posts, channel }: ChannelPostsProps) => {
  const classNames = getClassNames()

  return (
    <>
      {posts.map((post, index) => (
        <div className={classNames.card} key={index}>
          <PostCard key={index} post={post} channel={channel} navigate={() => {}} />
        </div>
      ))}
    </>
  )
}
