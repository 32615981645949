import React from 'react'
import { mergeStyleSets, ITheme, useTheme } from '@fluentui/react'

const getClassNames = (theme: ITheme, width: number) => {
  return mergeStyleSets({
    card: {
      margin: '0 auto',
      maxWidth: width, // 4 columns for grid
      minWidth: 280, // for Galaxy Fold 280
      backgroundColor: theme.palette.white,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
    },
  })
}

export interface WidgetContainerProps {
  children: React.ReactNode
  width: number
}

export const WidgetContainer = ({ children, width }: WidgetContainerProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme, width)
  return <div className={classNames.card}>{children}</div>
}
