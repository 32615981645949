import { Channel } from "@notidar/api"
import { ChannelIdentifier, PostId } from "@notidar/content"

export const notidarLink = 'https://notidar.com'

export function getNotidarLink() {
    return notidarLink;
}

export function getChannelLink(channel: Channel | ChannelIdentifier) {
    return `${notidarLink}/channel/${(channel as Channel).name ?? channel as ChannelIdentifier}`
}

export function getPostLink(channel: Channel | ChannelIdentifier, postId: PostId) {
    return `${notidarLink}/channel/${(channel as Channel).name ?? channel as ChannelIdentifier}/post/${postId}`
}