import { Channel, Post } from '@notidar/api'
import { WidgetBody } from '../components/Body'
import { WidgetFooter } from '../components/Footer'
import { PostCard } from '@notidar/content/src/cards/PostCard'
import { WidgetHeader } from '../components/Header'
import { WidgetContainer } from '../components/Container'
import { footerHeight, headerHeight } from '../View'

export interface ChannelPostWidgetProps {
  channel: Channel
  post: Post
  width: number
  height: number
  notidarLink: string
  channelLink: string
  postLink: string
}

export const ChannelPostWidget = ({
  channel,
  post,
  notidarLink,
  channelLink,
  postLink,
  width,
  height,
}: ChannelPostWidgetProps) => {
  return (
    <WidgetContainer width={width}>
      <WidgetHeader notidarLink={notidarLink} channelLink={channelLink} channel={channel} state='succeeded' />
      <WidgetBody maxHeight={height - headerHeight - footerHeight}>
        <PostCard channel={channel} post={post} navigate={() => {}} enableManagement={false} />
      </WidgetBody>
      <WidgetFooter state='seeMore' redirectLink={postLink} />
    </WidgetContainer>
  )
}
