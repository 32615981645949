import { ITheme, mergeStyleSets, useTheme } from '@fluentui/react'
import { Text } from '@fluentui/react'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    text: {
      backgroundColor: theme.palette.white,
      padding: '5px 10px',
    },
  })
}

export interface ChannelDescriptionProps {
  description: string
}

export const ChannelDescription = ({ description }: ChannelDescriptionProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme)

  return (
    <div className={classNames.text}>
      <Text>{description}</Text>
    </div>
  )
}
