import { useEffect, useState } from 'react'
import { EmptyWidget } from './EmptyWidget'
import { ChannelIdentifier, PostId } from '@notidar/content'
import { ChannelFeedWidget } from './FeedWidget'
import { ApiClient } from '../api'
import { Channel, Post } from '@notidar/api'
import { ChannelWidget } from './ChannelWidget'
import { ChannelPostWidget } from './PostWidget'
import { LoadingWidget } from './LoadingWidget'
import { getChannelLink, getNotidarLink, getPostLink } from '../utils'
import { ErrorWidget } from './ErrorWidget'

export type WidgetType = 'feed' | 'post' | 'channel'
export type WidgetTheme = 'light' | 'dark'
export type WidgetLang = 'en' | 'pl' | 'ru'

export interface WidgetProps {
  channelIdentifier?: ChannelIdentifier
  postId?: string
  type: WidgetType
  height: number
  width: number
  theme?: string
  lang?: string
}

type ChannelState = {
  channel?: Channel | null
  isLoading: boolean
  error?: string
}

type PostState = {
  post?: Post | null
  isLoading: boolean
  error?: string
}

type FeedState = {
  posts?: Post[] | null
  isLoading: boolean
  error?: string
}

export const Widget = ({ height, width, type, channelIdentifier, postId }: WidgetProps) => {
  const [postState, setPostState] = useState<PostState>({
    isLoading: true,
    post: undefined,
  })

  const [feedState, setFeedState] = useState<FeedState>({
    isLoading: true,
    posts: undefined,
  })

  const [channelState, setChannelState] = useState<ChannelState>({
    isLoading: true,
    channel: undefined,
  })

  // fetch channel
  useEffect(() => {
    const fetchChannel = async (channelIdentifier: ChannelIdentifier) => {
      setChannelState(prev => ({ ...prev, channel: undefined, isLoading: true, error: undefined }))
      const response = await ApiClient.getChannelAsync(channelIdentifier)
      setChannelState(prev => ({ ...prev, channel: response.data.channel, isLoading: false, error: undefined }))
    }

    if (channelIdentifier) {
      fetchChannel(channelIdentifier).catch(e => {
        if ((e as Response).status === 404) {
          setChannelState(prev => ({ ...prev, channel: null, isLoading: false }))
        }
        setChannelState(prev => ({ ...prev, channel: null, isLoading: false, error: 'ERROR' }))
      })
    } else {
      setChannelState(prev => ({ ...prev, channel: null, isLoading: false }))
    }
  }, [channelIdentifier, setChannelState])

  //fetch post
  useEffect(() => {
    const fetchPost = async (channelIdentifier: ChannelIdentifier, postId: PostId) => {
      setPostState(prev => ({ ...prev, post: undefined, isLoading: true, error: undefined }))
      const response = await ApiClient.getChannelPostAsync(postId)
      setPostState(prev => ({ ...prev, post: response.data.post, isLoading: false }))
    }

    if (channelIdentifier && postId && type === 'post') {
      fetchPost(channelIdentifier, postId).catch(e => {
        if ((e as Response).status === 404) {
          setPostState(prev => ({ ...prev, post: null, isLoading: false }))
        }
        setPostState(prev => ({ ...prev, post: null, isLoading: false, error: 'ERROR' }))
      })
    } else {
      setPostState(prev => ({ ...prev, post: null, isLoading: false }))
    }
  }, [channelIdentifier, postId, type, setPostState])

  //fetch feed
  useEffect(() => {
    const fetchPosts = async (channelIdentifier: ChannelIdentifier) => {
      setFeedState(prev => ({ ...prev, posts: undefined, isLoading: true, error: undefined }))
      const response = await ApiClient.getPostsAsync(channelIdentifier)
      setFeedState(prev => ({ ...prev, posts: response.data.posts, isLoading: false }))
    }

    if (channelIdentifier && type === 'feed') {
      fetchPosts(channelIdentifier).catch(e => {
        if ((e as Response).status === 404) {
          setFeedState(prev => ({ ...prev, posts: null, isLoading: false }))
        }
        setFeedState(prev => ({ ...prev, posts: null, isLoading: false, error: 'ERROR' }))
      })
    } else {
      setFeedState(prev => ({ ...prev, posts: null, isLoading: false }))
    }
  }, [channelIdentifier, postId, type, setFeedState])

  if (postState.isLoading || channelState.isLoading || feedState.isLoading) {
    return <LoadingWidget notidarLink={getNotidarLink()} width={width} />
  }

  if (postState.error || channelState.error || feedState.error) {
    return <ErrorWidget width={width} notidarLink={getNotidarLink()} />
  }

  if (type === 'channel' && channelState.channel) {
    return (
      <ChannelWidget
        channel={channelState.channel}
        notidarLink={getNotidarLink()}
        channelLink={getChannelLink(channelState.channel ?? channelIdentifier)}
        width={width}
        height={height}
      />
    )
  }

  if (type === 'post' && channelState.channel && postState.post) {
    return (
      <ChannelPostWidget
        notidarLink={getNotidarLink()}
        channelLink={getChannelLink(channelState.channel ?? channelIdentifier)}
        postLink={getPostLink(channelState.channel ?? channelIdentifier, postState.post.postId)}
        width={width}
        height={height}
        channel={channelState.channel}
        post={postState.post}
      />
    )
  }

  if (type === 'feed' && channelState.channel && feedState.posts && feedState.posts.length > 0) {
    return (
      <ChannelFeedWidget
        notidarLink={getNotidarLink()}
        channelLink={getChannelLink(channelState.channel ?? channelIdentifier)}
        width={width}
        height={height}
        channel={channelState.channel}
        posts={feedState.posts}
      />
    )
  }

  return <EmptyWidget notidarLink={getNotidarLink()} width={width} />
}
