import { ITheme, mergeStyleSets, useTheme } from '@fluentui/react'
import React from 'react'

const getClassNames = (theme: ITheme, maxHeight: number) => {
  return mergeStyleSets({
    container: {
      borderWidth: '1px 0px 1px 0px',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      backgroundColor: theme.palette.neutralLight,
      overflow: 'auto',
      maxHeight: maxHeight - 4 /* borders */,
    },
  })
}

export interface WidgetBodyProps {
  children: React.ReactNode
  maxHeight: number
}

export const WidgetBody = ({ children, maxHeight }: WidgetBodyProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme, maxHeight)

  return <div className={classNames.container}>{children}</div>
}
